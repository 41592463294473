import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-115 pb-100 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
              {/*  <img src={publicUrl+"assets/img/others/11.png"} alt="About Us Image" /> */}
              <img src={publicUrl+"assets/img/others/gabriele_giacca.png"} alt="About Us Image" /> 
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">About Us</h6>
			            <h1 className="section-title">Dream Rental Homes in Mallorca </h1>
			            <p>Hi, I'm Gabriele. With more than 100 satisfied guests, we offer the best turist home rental experience in Mallorca.
                  </p>
			          </div>                        
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-house-4" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">The Perfect Residency</a></h4>
			              <p>Find your perfect residency for short term turistic stay</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-call-center-agent" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">Our agents are always available for you</a></h4>
			              <p>We are available around the clock to help make your stay memorable.</p>
			            </div>
			          </div>
                {/*    
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-maps-and-location" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">Built-in Storage Cupboards</a></h4>
			              <p>Lorem ipsum dolor sit amet, consectetur adipisic do eiusmod tempor incididunt ut labore et</p>
			            </div>
                </div>
                */}
			          
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV3