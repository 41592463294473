import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import parse from 'html-react-parser';
import { Elements, ElementsConsumer, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutFormFun from "./checkoutStripeForm"


import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda'; // npm install aws-sdk

const stripePromise = loadStripe('pk_test_51LqdjKK8aWt1A2XnzWVA90bTfCcQyvZI0c6edZbiQ7VVqvZuHVufgW8hqm9zNFfZJpoBSfM2BhfLB1ymdGXIhLqP009RsMOmcg');

class Checkout extends Component {

	constructor(props) {
		super(props)
		this.state = {
			payment_requested: false,
			options: {}
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({
			spin_button: true
			}
		)

		let total_checkout = 200;
		var AWS = require('aws-sdk');

		const lambda = new Lambda({
			region: "eu-west-1",
			credentials: AWS.config.credentials
		});
		const intent = await lambda.invoke({
			FunctionName: 'arn:aws:lambda:eu-west-1:646450399623:function:lbStripe-staging',
			Payload: JSON.stringify({
				amount: total_checkout,
			  })
			}).promise()
			.then(invoked => {
				let res = JSON.parse(invoked["Payload"]);
				//console.log("Response", res);
				let body = JSON.parse(res.body);
				if (res.statusCode == 200) {
					this.setState({
						payment_requested: true,
						spin_button: false,
						options: {
							clientSecret: body.paymentIntent.client_secret,
							appearance: {theme: 'flat'},
						}
					})
				}
				return body.paymentIntent;
			})
		
	}

	renderStripeElem () {
		if (this.state.payment_requested) {
			return  (
				<Elements stripe={stripePromise} options={this.state.options}>
					<CheckoutFormFun />
				</Elements>
			);
		} else{
			return (<div></div>);
		}

	}

	render() {
		console.log("Rendering checkout component");
		let res = <div className="ltn__checkout-area mb-105">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__checkout-inner">
							<div className="ltn__checkout-single-content ltn__returning-customer-wrap">
								<h5>Returning customer? <a className="ltn__secondary-color" href="#ltn__returning-customer-login" data-bs-toggle="collapse">Click here to login</a></h5>
								<div id="ltn__returning-customer-login" className="collapse ltn__checkout-single-content-info">
									<div className="ltn_coupon-code-form ltn__form-box">
										<p>Please login your accont.</p>
										<form action="#">
											<div className="row">
												<div className="col-md-6">
													<div className="input-item input-item-name ltn__custom-icon">
														<input type="text" name="ltn__name" placeholder="Enter your name" />
													</div>
												</div>
												<div className="col-md-6">
													<div className="input-item input-item-email ltn__custom-icon">
														<input type="email" name="ltn__email" placeholder="Enter email address" />
													</div>
												</div>
											</div>
											<button className="btn theme-btn-1 btn-effect-1 text-uppercase">Login</button>
											<label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Remember me</label>
											<p className="mt-30"><a href="register.html">Lost your password?</a></p>
										</form>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div className="col-lg-6">
						<div className="shoping-cart-total mt-50">
							<form onSubmit={this.handleSubmit}>
								<h4 className="title-2">Cart Totals</h4>
								<table className="table">
									<tbody>
										<tr>
											<td>Test payment </td>
											<td>€ 1.00</td>
										</tr>
										<tr>
											<td><strong>Order Total</strong></td>
											<td><strong>$ 1.00</strong></td>
										</tr>
									</tbody>
								</table>
								<button disabled={this.state.payment_requested} className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
									<span id="button-text">
										{this.state.spin_button ? <div className="spinner" id="spinner"></div> : "Place order"}
									</span>									
								</button>
							</form>
							{this.renderStripeElem()}
						</div>
					</div>
				</div>
			</div>
		</div>

	return res

	}
}


export default Checkout