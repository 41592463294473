import React, { Component } from 'react';

class PaymentOKComponent extends Component {

	render() {
		return <div className="ltn__checkout-area mb-105">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__checkout-inner">
							<div className="ltn__checkout-single-content ltn__returning-customer-wrap">
								<h5>Payment was successful!!</h5>
								
							</div>

						</div>
					</div>

				</div>
			</div>

		</div>
		 
			

	}
}


export default PaymentOKComponent