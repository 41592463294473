import React, { useState, useEffect } from "react";
import { CardElement, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutFormFun() {
  
 // 1️⃣ Setup state to track client secret, errors and checkout status
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  // 2️⃣ Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    // 4️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
   // 5️⃣ Confirm Card Payment.
    const payload = await stripe.confirmPayment({
		elements,
		confirmParams: {
			return_url: 'http://deluxebaleares.com/#/payment-success'
		}
	});
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };
// 6️⃣ Construct UI.
return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="card-element" options={""} onChange={handleChange}/>
      <button disabled={processing || disabled || succeeded} id="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner"></div> : "Pay"}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">{error}</div>
      )}
    </form>
  );
}