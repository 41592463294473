import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Checkout from './shop-components/checkout';
import PaymentOKComponent from './shop-components/paymentOK';
import Footer from './global-components/footer';

const PaymentOK = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Checkout - Payment succeded!" />
        <Checkout />
        <PaymentOKComponent />
        <Footer />
    </div>
}

export default PaymentOK

